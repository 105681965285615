import React, { useState, useEffect } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

const CUSTOMER_REGISTER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const CustomerRegistrationContainer = styled.form`
  & .field {
    width: 100%;
    margin: 0 0 20px 0;

    &.mailing-signup {
      display: grid;
      grid-template-columns: 50px auto;
      align-items: baseline;

      margin: 35px 0 50px 0;

      & label {
        max-width: 440px;
      }

      & .marker-container {
        position: relative;
        z-index: 100;

        & input {
          display: none;
        }

        & .marker {
          display: block;
          width: 11px;
          height: 11px;
          text-decoration: none;

          background-color: #fff;

          z-index: 100;

          top: 0;
          left: 0;
          border: 1px solid #09184f;
          border-radius: 100%;

          margin: 7px 20px 0 20px;
          position: relative;

          &::after {
            content: "";
            width: 5px;
            height: 5px;
            position: absolute;
            top: 2px;
            left: 2px;

            border-radius: 100%;
            background: #09184f;

            opacity: 0;
            transition: 250ms opacity ease;
          }

          &:hover,
          &.checked {
            &::after {
              opacity: 1;
            }
          }

          @media (max-width: 500px) {
            width: 12px;
            height: 12px;

            &::after {
              width: 6px;
              height: 3px;
              top: 2px;
              left: 2px;
            }
          }
        }
      }
    }

    &.privacy-policy {
      margin: 50px 0 20px 0;
    }

    & input {
      width: 100%;

      height: 50px;
      line-height: 50px;

      border: 1px solid #09184f;
      box-sizing: border-box;

      padding: 0 20px;

      ::-webkit-input-placeholder {
        color: #09184f;
      }
      ::-moz-placeholder {
        color: #09184f;
      }
      :-ms-input-placeholder {
        color: #09184f;
      }
      :-moz-placeholder {
        color: #09184f;
      }
    }
  }

  & button {
    width: 295px;
    height: 50px;

    font-size: 12px;

    background: #d3d3d3;
    color: #09184f;

    margin: 0 30px 0 0;

    text-transform: uppercase;

    @media (max-width: 500px) {
      width: 100%;
    }

    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0.2em;

    transition: text-decoration-color 300ms;

    &:hover {
      text-decoration-color: #09184f;
    }
  }
`;

export const CustomerRegistration = ({ currentLanguage, accountText }) => {
  const [registerCustomer, { data, loading, error }] =
    useMutation(CUSTOMER_REGISTER);

  const [firstName, setFirstName] = useState(``);
  const [lastName, setLastName] = useState(``);
  const [email, setEmail] = useState(``);
  const [password, setPassword] = useState(``);
  const [acceptsMarketing, setAcceptsMarketing] = useState(false);

  // Message
  const [userSignupMessage, setUserSignupMessage] = useState(null);

  useEffect(() => {
    if (userSignupMessage !== null) {
      const closeForm = setTimeout(() => {
        setUserSignupMessage(null);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [userSignupMessage]);

  if (loading || error) return null;

  return (
    <CustomerRegistrationContainer>
      {userSignupMessage === null ? (
        <>
          <div className="field">
            <input
              className="input"
              type="text"
              id="registerFirstName"
              placeholder={currentLanguage === `en` ? `First Name` : `Prénom`}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>

          <div className="field">
            <input
              className="input"
              type="text"
              id="registerLastName"
              placeholder={
                currentLanguage === `en` ? `Last Name` : `Nom de famille`
              }
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>

          <div className="field">
            <input
              className="input"
              type="email"
              id="registerEmail"
              placeholder={
                currentLanguage === `en` ? `Email address` : `adresse e-mail`
              }
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="field">
            <input
              className="input"
              type="password"
              id="registerPassword"
              placeholder={
                currentLanguage === `en` ? `Password` : `mot de passe`
              }
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="field mailing-signup">
            <div className="marker-container">
              <input
                className="input checkbox"
                type="checkbox"
                id="acceptsMarketing"
                name={`acceptsMarketing`}
                value={acceptsMarketing}
                checked={acceptsMarketing}
                onChange={(e) => {
                  setAcceptsMarketing(!acceptsMarketing);
                }}
              />

              <button
                type="button"
                className={`marker ${acceptsMarketing ? `checked` : ``}`}
                onClick={() => setAcceptsMarketing(!acceptsMarketing)}
              />
            </div>

            <label>{accountText.newsletter_signup_text}</label>
          </div>

          <div className="field">
            <button
              className="button uppercase account-button register"
              type="submit"
              onClick={() => {
                registerCustomer({
                  variables: {
                    input: {
                      firstName: firstName,
                      lastName: lastName,
                      email: email,
                      password: password,
                      acceptsMarketing: acceptsMarketing,
                    },
                  },
                }).then((result) => {
                  if (
                    result.data.customerCreate.customerUserErrors.length >= 1
                  ) {
                    setUserSignupMessage(
                      result.data.customerCreate.customerUserErrors[0].message
                    );
                  } else {
                    if (currentLanguage === `en`) {
                      setUserSignupMessage(
                        `Thank you for creating an account. A confirmation email has been sent to your email address.`
                      );
                    } else {
                      setUserSignupMessage(
                        `Merci d'avoir créé un compte. Un e-mail de confirmation vient de vous être envoyé.`
                      );
                    }

                    setFirstName("");
                    setLastName("");
                    setEmail("");
                    setPassword("");
                  }
                });
              }}
            >
              {currentLanguage === `en` ? `Create Account` : `Créer un compte`}
            </button>
          </div>

          <div className="field privacy-policy">
            <PrismicRichText field={accountText.privacy_policy_text.richText} />
          </div>
        </>
      ) : (
        <div className="signup-message">
          <p>{userSignupMessage}</p>
        </div>
      )}
    </CustomerRegistrationContainer>
  );
};
