import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";

const CUSTOMER_PASSWORD_RESET = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const CustomerLoginContainer = styled.form`
  & .field {
    width: 100%;
    margin: 0 0 20px 0;

    & input {
      width: 100%;

      height: 50px;
      line-height: 50px;

      border: 1px solid #09184f;
      box-sizing: border-box;

      padding: 0 20px;

      ::-webkit-input-placeholder {
        color: #09184f;
      }
      ::-moz-placeholder {
        color: #09184f;
      }
      :-ms-input-placeholder {
        color: #09184f;
      }
      :-moz-placeholder {
        color: #09184f;
      }
    }
  }

  & button {
    &.account-button.login {
      width: 295px;
      height: 50px;

      font-size: 12px;

      background: #d3d3d3;
      color: #09184f;

      margin: 0 30px 0 0;

      text-transform: uppercase;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0.2em;

    transition: text-decoration-color 300ms;

    &:hover {
      text-decoration-color: #09184f;
    }
  }
`;

export const CustomerForgotPassword = ({
  setPasswordForgot,
  currentLanguage,
}) => {
  const [recoverCustomer, { data, loading, error }] = useMutation(
    CUSTOMER_PASSWORD_RESET
  );

  const [email, setEmail] = useState(``);

  // Message
  const [message, setMessage] = useState(null);

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  return (
    <CustomerLoginContainer>
      <div className="field">
        <input
          className="input"
          type="email"
          id="forgotEmail"
          placeholder={
            currentLanguage === `en` ? `Email address` : `adresse e-mail`
          }
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="field">
        <button
          type="button"
          className="button uppercase forgot-password small"
          onClick={() => setPasswordForgot(false)}
        >
          {currentLanguage === `en` ? `Return to login` : `Retourner à`}
        </button>
      </div>

      <div className="field">
        <button
          type="submit"
          className="uppercase account-button login"
          onClick={(event) => {
            event.preventDefault();

            recoverCustomer({
              variables: {
                email: email,
              },
            })
              .then(() => {
                setMessage(
                  "We've sent you an email with a link to update your password."
                );
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          Reset Password
        </button>
      </div>
    </CustomerLoginContainer>
  );
};
