import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import styled from "styled-components";
import { navigate } from "gatsby";

const CUSTOMER_LOGIN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`;

const CustomerLoginContainer = styled.form`
  & .field {
    width: 100%;
    margin: 0 0 20px 0;

    & input {
      width: 100%;

      height: 50px;
      line-height: 50px;

      border: 1px solid #09184f;
      box-sizing: border-box;

      padding: 0 20px;

      ::-webkit-input-placeholder {
        color: #09184f;
      }
      ::-moz-placeholder {
        color: #09184f;
      }
      :-ms-input-placeholder {
        color: #09184f;
      }
      :-moz-placeholder {
        color: #09184f;
      }
    }
  }

  & button {
    &.account-button.login {
      width: 295px;
      height: 50px;

      font-size: 12px;

      background: #d3d3d3;
      color: #09184f;

      margin: 0 30px 0 0;

      text-transform: uppercase;

      @media (max-width: 500px) {
        width: 100%;
      }
    }

    text-decoration: underline;
    text-decoration-color: transparent;
    text-underline-offset: 0.2em;

    transition: text-decoration-color 300ms;

    &:hover {
      text-decoration-color: #09184f;
    }
  }
`;

export const CustomerLogin = ({
  handleCustomerAccessToken,
  currentLanguage,
  passwordForgot,
  setPasswordForgot,
}) => {
  const [loginCustomer, { data, loading, error }] = useMutation(CUSTOMER_LOGIN);

  const [email, setEmail] = useState(``);
  const [password, setPassword] = useState(``);

  // Message
  const [userSignupMessage, setUserSignupMessage] = useState(null);

  if (loading) return "Submitting...";
  if (error) return `Submission error! ${error.message}`;

  return (
    <CustomerLoginContainer>
      <div className="field">
        <input
          className="input"
          type="email"
          id="loginEmail"
          placeholder={
            currentLanguage === `en` ? `Email address` : `adresse e-mail`
          }
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="field">
        <input
          className="input"
          type="password"
          id="loginPassword"
          placeholder={currentLanguage === `en` ? `Password` : `mot de passe`}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <div className="field">
        <button
          type="button"
          className="button uppercase forgot-password small"
          onClick={() => setPasswordForgot(true)}
        >
          {currentLanguage === `en` ? `Forgot password` : `Mot de passe oublié`}
        </button>
      </div>

      <div className="field">
        <button
          type="submit"
          className="uppercase account-button login"
          onClick={(event) => {
            event.preventDefault();

            loginCustomer({
              variables: {
                input: {
                  email: email,
                  password: password,
                },
              },
            })
              .then((result) => {
                handleCustomerAccessToken(
                  result.data.customerAccessTokenCreate.customerAccessToken
                );

                window.location.reload();
              })
              .catch((err) => {
                console.error(err);
              });
          }}
        >
          {currentLanguage === `en` ? `Login` : `Se connecter`}
        </button>
      </div>
    </CustomerLoginContainer>
  );
};
