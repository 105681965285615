import React, { useContext } from "react";
import { navigate } from "gatsby";

// Context
import { StoreContext } from "../context/store";
import { CurrentLanguage } from "../context/current-language";

export const ConnectionLayout = (props, log) => {
  const [currentLanguage, setCurrentLanguage] = useContext(CurrentLanguage);

  let isAuthenticated =
    props.customerAccessToken &&
    props.customerAccessToken.expiresAt &&
    props.customerAccessToken.expiresAt > new Date().toISOString()
      ? true
      : false;

  return (
    <>
      {isAuthenticated
        ? typeof window !== "undefined"
          ? navigate(`/${currentLanguage}/account/order-history/`)
          : null
        : props.children}
    </>
  );
};
